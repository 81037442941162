import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" description='Уучлаарай таны хайсан хуудас байхгүй байна' />
    <div className="container ">
      <div className="flex items-center justify-center py-64 text-xl font-extrabold text-gray-300 uppercase max-h-24 ">
        Уучлаарай, таны хайж буй хуудас байхгүй байна.
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
